import React from 'react'
import Layout from "../layouts/de"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Datenschutz - Online Kreativagentur"
    description="Hier findest du alle wichtigen Daten über den Datenschutz, du kannst mir deine Anfrage auch an meine E-Mail-Adresse mail@lopezdesign.de schicken."
    >
    <Block color="white"
      left={
        <div className="grid__item space--top">
          <article className="space--bottom">
            <h1 className="shine space--bottom">Datenschutz</h1>
            <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: Cristian López</p>
            <h5>Ihre Betroffenenrechte</h5>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
            <ul>
              <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
              <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
              <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
              <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
              <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
              <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
            </ul>
            <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
            <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
            <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a target="_blank" rel="noreferrer" href="https://www.bfdi.bund.de">https://www.bfdi.bund.de</a>.</p>
          </article>
          <article className="space--bottom">
            <h5>Verwendung von Google Analytics</h5>
            <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein 
              Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“). 
              Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine 
              Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre 
              Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. 
              Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb 
              von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen 
              Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA 
              übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit 
              anderen Daten von Google zusammengeführt.
            </p>
            <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a target="_blank" rel="noreferrer" href="https://www.google.com/analytics/terms">https://www.google.com/analytics/terms</a>.</p>
            <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite 
              auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der 
              Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
            </p>
            <p>
              Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden 
              nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt 
              automatisch einmal im Monat.
            </p>
            <h5>Widerruf der Einwilligung:</h5>
            <p>
              Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie diesen Link anklicken. 
              Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für 
              diese Website und für diesen Browser zukünftig verhindert, solange das Cookie in Ihrem Browser installiert bleibt.
            </p>
            <p>
              Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer 
              Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht 
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
            </p>
            <p className="space--bottom">
              Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite 
              bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, 
              indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a target="_blank" rel="noreferrer" href="https://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On 
              zur Deaktivierung von Google Analytics.</a>
            </p>
            <h5>Verwendung von Scriptbibliotheken (Google Webfonts)</h5>
            <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
            <p className="space--bottom">Weitere Informationen zu Google Web Fonts finden Sie unter <a target="_blank" rel="noreferrer" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://www.google.com/policies/privacy/</a>.</p>
            <h5>Information über Ihr Widerspruchs­recht nach Art. 21 DSGVO</h5>
            <h5>Einzelfallbezogenes Widerspruchsrecht</h5>
            <p>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die 
              Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO 
              (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch 
              für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
            </p>
            <p>
              Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir 
              können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und 
              Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von 
              Rechtsansprüchen.
            </p>
            <h5>Empfänger eines Widerspruchs</h5>
            <p>Cristian López</p>
          </article>
          <article className="space--bottom">
            <h5>Änderung unserer Datenschutz­bestimmungen</h5>
            <p>
              Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen 
              entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer 
              Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
            </p>
          </article>
          <article className="space--bottom">
            <h5>Fragen an den Datenschutz­beauftragten</h5>
            <p>
              Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den 
              Datenschutz verantwortliche Person in unserer Organisation:
            </p>
            <p>Cristian López</p>
            <p>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für externe Datenschutzbeauftragte (Version #2020-09-30).</p>
          </article>
        </div>
      }
    />
  </Layout>
)

export default InfoPage

